<template>
   <div class="container" style="max-width:300px;">
   <form v-if="!signingIn && !waitForAdminMessage" class="form-signin" @submit.prevent="createAccount" autocomplete="off">
      <div class="img-fluid logo">
      <img src="@/assets/images/Yuha_TextLogo_Black.svg" class="img-fluid" />
      </div>
      <h1 class="h3 mb-3 font-weight-normal">Uitnodiging docent</h1>
      <br>

      <label for="new-inputName" class="visually-hidden">Naam</label>
      <input type="text" id="new-inputName" class="form-control" placeholder="Naam" v-model="newName" required autofocus autocomplete="new-name">
      <br>
      <label for="new-schoolName" class="visually-hidden">School</label>
      <input type="text" id="new-schoolName" class="form-control" placeholder="School" v-model="newSchoolName" required autofocus autocomplete="new-school">
      <br>
      <label for="new-inputEmail" class="visually-hidden">Email address</label>
      <input type="email" id="new-inputEmail" class="form-control" placeholder="Email adres" v-model="newEmail" required autofocus autocomplete="new-email">
      <br>
      <label for="new-inputPassword" class="visually-hidden">Wachtwoord</label>
      <input type="password" id="new-inputPassword" class="form-control" placeholder="Wachtwoord" v-model="newPassword" required autocomplete="new-password">
      <br>
      <label for="new-inputPasswordRepeat" class="visually-hidden">Wachtwoord herhalen</label>
      <input type="password" id="new-inputPasswordRepeat" class="form-control" placeholder="Wachtwoord herhalen" v-model="passwordRepeat" required autocomplete="new-password-repeat">
      <br>

      <button v-if="!signingIn && !waitForAdminMessage" class="btn btn-lg btn-primary btn-block" type="submit">Aanmelden</button>
    </form>

    <LoadingSpinner :loading="signingIn" />

    <div v-if="!signingIn && waitForAdminMessage">
      Bedankt voor het aanmelden.<br>
      Nadat een administrator je heeft toegewezen aan een school kan je <a href="#" @click="goToLogin()">Inloggen</a>.
    </div>
    
    <p v-if="errMsg && !waitForAdminMessage" class="mt-2">{{ errMsg }}</p>

  </div>
</template>

<script>
  import { auth, db, firestore } from '@/main'
  import { router } from '@/router'
  import LoadingSpinner from './Nested/LoadingSpinner.vue';

  export default {
    name: 'InvitationModule',
    data() {
        return {
            invitationId: "",
            schoolId: "",
            signingIn: false,
            newName: "",
            newEmail: "",
            newPassword: "",
            passwordRepeat: "",
            errMsg: null,
            waitForAdminMessage: false,
        };
    },
    mounted() {
        //this.invitationId = this.$route.params.invitationId;
        //this.schoolId = this.$route.params.schoolId;
    },
    methods: {
        createAccount() {
            if (this.newPassword == this.passwordRepeat) {
                this.createUserWithCredentials();
            }
            else {
                this.errMsg = "Wachtwoorden zijn niet hetzelfde. Controleer de wachtwoorden.";
            }
        },
        goToLogin() {
            router.push('/login');
        },
        createUserWithCredentials() {
            this.signingIn = true;
            auth.createUserWithEmailAndPassword(this.newEmail, this.newPassword).
                then((userCredential) => {
                userCredential.user.sendEmailVerification();
                let newUserId = userCredential.user.uid;
                console.log("Hi new user. " + newUserId);
                let timestamp = firestore.FieldValue.serverTimestamp();
                db.collection("/users").doc(newUserId).set({
                    created: timestamp,
                    schoolDescription: this.newSchoolName,
                    name: this.newName,
                    schoolId: null
                })
                    .then(() => {
                    this.signingIn = false;
                    this.waitForAdminMessage = true;
                })
                    .catch(error => {
                    console.log(error);
                    this.errMsg = error.message;
                    this.signingIn = false;
                });
            })
                .catch(error => {
                console.log(error);
                this.errMsg = error.message;
                this.signingIn = false;
            });
        }
    },
    components: { LoadingSpinner }
}
</script>

<style>
.container{
  text-align: center;
}
.logo{
  padding:30px;
}
</style>