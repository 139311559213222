<template>
    <div class="container-fluid">
      <div class="versionNumber">v{{ this.cachedAppVersion }}</div>
      <div class="row">
        <nav class="col-md-2 d-none d-md-block sidebar" v-if="showMenuSidebar">
          <div class="portrait-items">
            <img src="@/assets/images/Yuha_TextLogo_White.svg" style="margin-left: 20px; height:20px;" />
            <div class="hamburger" @click="this.$store.state.showMenu = !this.$store.state.showMenu">
              <fa icon="fa-solid fa-bars" />
            </div>
          </div>
          <div class="sidebar-sticky">
            <ul class="nav flex-column" :class="{ 'show': this.$store.state.showMenu }">
              <div class="yuha-brand img-fluid" style="padding:40px;">
                <router-link to="/dashboard"><img src="@/assets/images/Yuha_TextLogo_White.svg" class="img-fluid" alt="Yuha" /></router-link>
              </div>

              <router-link to="/settings">
                <div class="avatar d-flex justify-content-center rounded-circle" v-bind:style="[(this.$store.state.user && this.$store.state.user.avatar) ? { backgroundImage: 'url(' + this.$store.state.user.avatar + ')' } : {} ]"></div>
             </router-link>

              <li class="nav-item">
                <router-link to="/dashboard">
                <a class="nav-link" href="/dashboard" v-bind:class="{ 'active': $route.path == '/dashboard' }">
                  <fa icon="fa-solid fa-home" /> Dashboard
                </a>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/students">
                <a class="nav-link" href="/students" v-bind:class="{ 'active': $route.path == '/students' }">
                  <fa icon="fa-solid fa-users" /> Leerlingen
                </a>
               </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/teachers">
                <a class="nav-link" v-if="this.$store.state.user.admin" href="/teachers" v-bind:class="{ 'active': $route.path == '/teachers' }">
                  <fa icon="fa-solid fa-person-chalkboard" /> Leerkrachten
                </a>
               </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/activities/normal">
                <a class="nav-link" href="/activities/normal" v-bind:class="{ 'active': $route.path == '/activities/normal' }">
                  <fa icon="fa-solid fa-bars-staggered" /> Activiteiten
                </a>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/devices">
                <a class="nav-link" href="/devices" v-bind:class="{ 'active': $route.path == '/devices' }">
                  <fa icon="fa-solid fa-vr-cardboard" /> VR headsets
                </a>
               </router-link>
              </li>
              <li class="nav-item" v-if="this.$store.state.user.admin">
                <router-link to="/schools">
                <a class="nav-link" href="/schools" v-bind:class="{ 'active': $route.path == '/schools' }">
                  <fa icon="fa-solid fa-school" /> Scholen
                </a>
               </router-link>
              </li>
              <li class="nav-item" v-if="this.$store.state.user.admin">
                <router-link to="/games">
                <a class="nav-link" href="/games" v-bind:class="{ 'active': $route.path == '/games' }">
                  <fa icon="fa-solid fa-gamepad" /> Spellen
                </a>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/settings">
                <a class="nav-link" href="/settings" v-bind:class="{ 'active': $route.path == '/settings' }">
                  <fa icon="fa-solid fa-gear" /> Instellingen
                </a>
               </router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" @click="logout">
                  <fa icon="fa-solid fa-right-from-bracket" /> Uitloggen
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <main role="main" class="ml-sm-auto pt-5 px-4 pb-5" :class="showMenuSidebar ? 'col-md-8 offset-md-2' : 'col-md-12'">
          <router-view></router-view>
        </main>
      </div>
    </div>
</template>

<script>
import { auth } from './main';
import { db } from '@/main'
export default {
  name: 'App',
  computed: {
    showMenuSidebar() {
      //Exclude sidebar in following routes
      return this.$route.path !== '/login' && 
      (this.$route.path.indexOf('/invitation') < 0) &&
      (this.$route.path.indexOf('/logindevice') < 0) &&
      (this.$route.path.indexOf('/activities/hidden') < 0)
    }
  },
  data(){
    return {
      showMenu: false,
      cacheCheck: true,
      cachedAppVersion: 120
    }
  },
  mounted() 
  {
    if(!this.cacheCheck){
      return;
    }

    db.collection("/settings").doc("app").get().then((doc) => 
    {
        const appSettings = doc.data();

        console.log(`Version check. Local:${this.cachedAppVersion} == Remote:${appSettings.version}`);
        if(this.cachedAppVersion != String(appSettings.version))
        {
          console.log(`Refreshing cached index.html`);

          const baseURL = window.location.href.split("?");
          const redirectUrl = baseURL[0] + "?v=" + appSettings.version;
          window.location.href = redirectUrl;
        }
    });
  },
  methods: {
    logout(){
      auth.signOut();
    }
  }
};
</script>

<style>
.versionNumber{
  font-size: 12px;
  position:fixed;
  right: 10px;
  bottom: 10px;
  color:grey;
}
h1{
  color:#55a2f0;
}
a{
  color:#005fa5;
  cursor:pointer !important;
  text-decoration: none;
  font-weight: bold;
}
 table{
    table-layout: fixed;
 }

.btn{
  border-radius: 100px !important;
}
.form-select{
  border-radius: 100px !important;
}

/*Level and year for goals */
.rn{
  padding: 3px;
  border-radius: 30px;
  width: 25px;
  height: 25px;
  text-align: center;
  border: 1px solid rgb(22, 139, 148);
  font-size: 12px;
  background-color:rgb(33, 188, 226);
  font-weight: bold;
  color:white;
  margin-left: 2px;
  margin-right: 2px;
}
.year{
  padding: 3px;
  border-radius: 30px;
  height: 25px;
  min-width: 25px;
  text-align: center;
  border: 1px solid rgb(45, 177, 41);
  font-size: 12px;
  background-color:rgb(106, 209, 106);
  font-weight: bold;
  color:white;
}

.avatar{
  margin-left:auto; 
  margin-right: auto; 
  width:100px; 
  height:100px;
  margin-bottom: 20px;
  border: 2px solid white;
  background-position: center; 
  background-size: cover; 
  background-color:grey; 
}

.portrait-items{
  display: none;
  justify-content: space-between;
  align-items: center;
}
.hamburger{
  color:white;
  border-radius: 10px;
  background-color: #4fd3fc;
  width:40px;
  height:40px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}
.hamburger:hover{
  background-color: #123f4d;
}

.tinyRemove{
  float:right;
}

/* Some shadows to make things pop like buttons */
table .btn{
  -webkit-box-shadow: 2px 2px 4px 1px rgba(0,0,0,0.35); 
  box-shadow: 2px 2px 4px 1px rgba(0,0,0,0.35);
}

/* Add a scale in animation to all elements */
.animateIn {
  animation: scaleIn 0.3s;
}

.animateDown {
  transform-origin: top;
  animation: scaleDown 0.3s;
}

/* Define the animation keyframes */
@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scaleDown {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

/* Define the animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.nav a { 
  text-decoration: none; 
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  max-width: 200px;
  min-width: 200px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  background-color:#55a2f0;
}

@media (min-width: 768px){
  .offset-md-2 {
      margin-left: max(200px, 17%);
  }
}

/* Portrait menu */
@media (max-width: 767px){
  .sidebar {
    display: block !important;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    position: static;
    z-index: 100; /* Behind the navbar */
    padding: 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    background-color:#55a2f0;
    overflow: hidden;
  }
  .sidebar-sticky .nav{
    display:none;
  }
  .sidebar-sticky .nav.show{
    display:flex;
  }
  .yuha-brand{
    display:none;
    text-align:center;
  }

  .sidebar .avatar{
    display:none;
  }
  .portrait-items{
    min-height:50px;
    display: flex;
  }
}

.yuha-brand{
  background-color:#55a2f0;
}

.sidebar-sticky {
  font-size: larger;
  position: -webkit-sticky;
  position: sticky;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  margin: 10px;
  color: rgb(255, 255, 255);
  border-radius: 100px;
  text-wrap: nowrap;
}

.sidebar .nav-link.active{
  background-color:white;
  color:#55a2f0;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/* 
* Nav
*/

.back{
  cursor:pointer;
}

/* 
* Tooltips
*/

/* Override background color */
.tooltip .tooltip-inner {
  font-weight: bold;
  color:white;
  background-color: purple;
}

/* Override arrow color */
.tooltip.bottom .tooltip-arrow{
    top:0;
    left:50%;
    margin-left:-5px;
    border-left:5px solid transparent;
    border-right:5px solid transparent;
    border-bottom:5px solid purple
}

/* 
* Results
*/
.mainstats{
  max-width: 120px;
}
.loggedItem
{
  display:inline-block;
  font-weight:bold;
  font-size: 14px;
  margin:5px;
  padding:10px;
  border-radius:10px; 
  background-color: #e5f9ff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 4px;
}
.good{
  color:green;
  background-color: #c2f7b3;
}
.bad{
  color:red;
  background-color: #f9d0d0;
}

/*
 * Navbar
 */

.navbar-brand {
  text-align: center;
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: #55a2f0;
}
.removeRoundnessLeft{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important
}
/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }
</style>