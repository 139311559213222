<template>
  <h1>Schools</h1>
  <div class="smoothContainer admin" v-if="this.$store.state.user.admin">
    <h5>
      Admin opties
    </h5>

    <hr>

    <div class=" smoothContainer mb-3">
      <h5>School aanmaken</h5>
      <form @submit.prevent="addSchool" autocomplete="off">
        <div class="form-floating mb-2">
          <input class="form-control" type="text" v-model="newSchoolName" id="new-SchoolName" placeholder="School naam"
            required autocomplete="new-school">
          <label for="new-UserEmail">School naam</label>
        </div>
        <button class="btn btn-primary" type="submit">Maak school aan</button>
      </form>
    </div>

    <div class="smoothContainer mb-3">
      <div class="row">
        <div class="col-lg-6">
          <h5>School kiezen</h5>
          <h6 v-if="this.selectedSchool">
            Actieve school: {{ this.selectedSchool.name }}
          </h6>
          <ul class="list-group animateIn">
            <li class="list-group-item animateIn" v-for="(school, schoolIndex) in schools" :key="schoolIndex">
              <a @click="selectSchool(school)" class="text-decoration-none">{{ school.name }}
                <fa icon="fa-solid fa-school" v-if="school == this.selectedSchool" />
              </a>
              <a class="tinyRemove text-danger ms-3" @click="trashSchool(school)">
                <fa icon="fa-solid fa-remove" />
              </a>
            </li>
          </ul>
        </div>

        <div class="col-lg-6">
          <div v-if="this.selectedSchool && this.selectedSchool.users" class="mb-3">
            <h5>"{{ this.selectedSchool.name }}"</h5>
            <h6>Instellingen:</h6>

            <div class="form-floating mb-2">
              <input class="form-control" type="text" v-model="this.selectedSchool.name" id="edit-SchoolName" placeholder="School naam" required autocomplete="new-school" @change="nameSettingChanged()">
              <label for="edit-SchoolName">School naam</label>
            </div>

            <div class="btn-group mb-3" role="group" aria-label="Domain type">
              <button type="button" class="btn me-2" :class="{'btn-success': this.selectedSchool.isBso, 'btn-outline-secondary': !this.selectedSchool.isBso}" @click="bsoSettingChanged()">BSO</button>
              <button type="button" class="btn me-3" :class="{'btn-success': this.selectedSchool.isSchool, 'btn-outline-secondary': !this.selectedSchool.isSchool}" @click="schoolSettingChanged()">School</button>
            </div>

            <br>
            <!--Button to save settings-->
            <button v-if="this.selectedSchool.wasChanged" class="btn btn-primary mb-5" type="submit" @click="saveSchoolSettings()">Instellingen wijziging opslaan</button>

            <h6>Gebruikers:</h6>
            <p v-if="this.selectedSchool.users.length == 0">
              Nog geen gebruikers.<br>
            </p>
            <ul class="list-group animateIn">
              <li class="list-group-item animateIn" v-for="(user, userIndex) in this.selectedSchool.users"
                :key="userIndex">
                <a @click="unassignUser(user)" class="text-decoration-none">
                  <fa icon="fa-solid fa-user" /> {{ user.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="this.selectedSchool" class=" smoothContainer mb-3">
      <h5 class="mb-3">Nieuwe gebruikers toewijzen aan: <b>{{ this.selectedSchool.name }}</b></h5>

      <ul class="list-group animateIn">
        <li class="list-group-item animateIn" v-for="(unassignedUser, unassignedUserIndex) in unassignedUsers"
          :key="unassignedUserIndex">
          <a v-if="!unassignedUser.assigned" @click="assignUser(unassignedUser)" class="text-decoration-none">
            <fa icon="fa-solid fa-user-plus" /> {{ unassignedUser.name }} - "{{
              unassignedUser.schoolDescription }}" <i>{{ unassignedUser.made }}</i>
          </a>
          <a class="tinyRemove text-danger ms-3" @click="removeUser(unassignedUser)">
              <fa icon="fa-solid fa-remove" />
          </a>
        </li>
      </ul>
      <br>
      <p class="text-center">
        Docenten kunnen zich aanmelden via de uitnodiging url:<br>
        <a target="_blank" href="https://portal.yuha.io/invitation/">https://portal.yuha.io/invitation/</a><br><br>
        Daarna kan je ze hier toewijzen aan een school.
      </p>
    </div>

    <div class=" smoothContainer mb-3">
      <h5>Verwijderde scholen <fa icon="fa-solid fa-trash" /></h5>

      <ul class="list-group animateIn">
          <li class="list-group-item animateIn" v-for="(school, schoolRemovedIndex) in removedSchools" :key="schoolRemovedIndex">
            <i>{{ school.name }} </i> 
            <a class="tinyRemove text-danger ms-3" @click="removeSchool(school)">
              <fa icon="fa-solid fa-remove" />
            </a>
            <a @click="restoreSchool(school)" class="text-decoration-none float-end me-2">
              <fa icon="fa-solid fa-trash-restore" />
            </a> 
          </li>
      </ul>
    </div>

  </div>
</template>

<script>
import { db, auth, firebase, firestore } from '@/main';

export default {
  name: 'SettingsModule',
  data() {
    return {
      currentUserToken: "",
      addedUserId: "",
      createUserError: "",
      selectedSchool: null,
      newSchoolName: "",
      newUserName: "",
      newUserEmail: "",
      newUserPassword: "",
      schools: [],
      removedSchools: [],
      unassignedUsers: []
    }
  },
  methods: {
    newInviteUrl() {
      let newDoc = db.collection("/schools").doc(this.selectedSchool.id).collection("invitations").doc();
      newDoc.set({
        createdByUser: auth.currentUser.uid
      });

      this.selectedSchool.inviteUrl = "https://portal.yuha.io/invitation/" + this.selectedSchool.id + "/" + newDoc.id;
    },
    assignUser(user) {
      db.collection("/users").doc(user.id).update({
        schoolId: this.selectedSchool.id,
      }).then(() => {
        user.assigned = true;
        this.getSchoolUsers();
        this.getUnassignedUsers();
      });
    },
    unassignUser(user) {
      db.collection("/users").doc(user.id).update({
        schoolId: null,
      }).then(() => {
        user.assigned = false;
        this.getSchoolUsers();
        this.getUnassignedUsers();
      });
    },
    gotSchools(items) {
      console.log(items);
      let _schools = [];
      items.forEach((item) => {
        let data = item.data();
        data.id = item.id;
        data.isBso = (data.isBso == undefined) ? false : data.isBso;
        data.isSchool = (data.isSchool == undefined) ? true : data.isSchool;
        _schools.push(data);

        if(this.$store.state.schoolId == data.id)
        {
          this.selectSchool(data);
        }
      });
      this.schools = _schools;
      this.getSchoolUsers();
    },
    gotRemovedSchools(items) {
      console.log(items);
      let _schools = [];
      items.forEach((item) => {
        let data = item.data();
        data.id = item.id;
        _schools.push(data);
      });
      this.removedSchools = _schools;
    },
    addSchool() {
      db.collection("/schools").doc().set({
        name: this.newSchoolName,
        isBso: false,
        isSchool: true,
        removed: false
      }).then(() => {
        this.getSchools();
      });
      this.newSchoolName = "";
    },
    nameSettingChanged(){
      this.selectedSchool.wasChanged = true;
    },
    bsoSettingChanged(){
      this.selectedSchool.isBso = !this.selectedSchool.isBso;

      this.selectedSchool.wasChanged = true;

      //If bso is disabled, and school is disabled, enable school
      if (!this.selectedSchool.isBso && !this.selectedSchool.isSchool) {
        this.selectedSchool.isSchool = true;
      }

      this.$store.state.showBsoFeatures = this.selectedSchool.isBso;
    },
    schoolSettingChanged(){
      this.selectedSchool.isSchool = !this.selectedSchool.isSchool;

      this.selectedSchool.wasChanged = true;

      //If bso is disabled, and school is disabled, enable bso
      if (!this.selectedSchool.isBso && !this.selectedSchool.isSchool) {
        this.selectedSchool.isBso = true;
      }

      this.$store.state.showSchoolFeatures = this.selectedSchool.isSchool;
    },
    saveSchoolSettings() {
      db.collection("/schools").doc(this.selectedSchool.id).update({
        name: this.selectedSchool.name,
        isBso: this.selectedSchool.isBso,
        isSchool: this.selectedSchool.isSchool
      }).then(() => {
        this.selectedSchool.wasChanged = false;
        console.log("Saved school settings");
      });
    },
    trashSchool(school) {
      if (prompt("Type 'VERWIJDER' om de school " + school.name + " te verwijderen") == 'VERWIJDER') {
        console.log("Moving school to trash " + school.name);
        db.collection("/schools").doc(school.id).update({
          removed: true
        }).then(() => {
          this.getSchools();
          this.getRemovedSchools();
        });
      }
    },
    restoreSchool(school){
      if (confirm("Weet je zeker dat je de verwijderde school " + school.name + " wilt herstellen?")) {
        db.collection("/schools").doc(school.id).update({
          removed: false
        }).then(() => {
          this.getSchools();
          this.getRemovedSchools();
        });
      }
    },
    removeSchool(school) {
      if (prompt("Type 'VERWIJDER' om de school " + school.name + " volledig te verwijderen.\nDe gekoppelde activiteiten, gebruikers en apparaten zullen worden verwijderd.") == 'VERWIJDER') {
        const schoolId = school.id;
        console.log("Removing school " + school.name + " with id " + schoolId);

        db.collection("/schools").doc(schoolId).delete().then(() => {
          this.getRemovedSchools();
        });

        //Remove activities tied to this school
        db.collection("/activities").where('schoolId', '==', schoolId).get().then((items) => {
          items.forEach((item) => {
            db.collection("/activities").doc(item.id).delete();
          });
        });

        //Remove users tied to this school
        db.collection("/users").where('schoolId', '==', schoolId).get().then((items) => {
          items.forEach((item) => {
            db.collection("/users").doc(item.id).delete();
          });
        });

        //Remove devices linked to school
        db.collection("/devices").where('schoolId', '==', schoolId).get().then((items) => {
          items.forEach((item) => {
            db.collection("/devices").doc(item.id).delete();
          });
        });
      }
    },
    removeUser(user) {
      if (prompt("Type 'VERWIJDER' om de gebruiker " + user.name + " te verwijderen") == 'VERWIJDER') {
        console.log("Removing " + user.name);
        db.collection("/users").doc(user.id).delete().then(() => {
          this.getUnassignedUsers();
          this.getSchoolUsers();
        });
      }
    },
    selectSchool(school) {
      this.$store.state.schoolId = school.id;
      console.log("Selected school " + school.name + " with id " + school.id);

      this.selectedSchool = school;

      //Set isBso false and isSchool true by default if field not exist
      if (this.selectedSchool.isBso == undefined) {
        this.selectedSchool.isBso = false;
      }
      if (this.selectedSchool.isSchool == undefined) {
        this.selectedSchool.isSchool = true;
      }

      //Set default settings for school
      this.$store.state.showBsoFeatures = this.selectedSchool.isBso;
      this.$store.state.showSchoolFeatures = this.selectedSchool.isSchool;

      this.getSchoolUsers();
    },
    getSchools() {
      db.collection("/schools").where('removed', '==', false).get().then(this.gotSchools);
    },
    getRemovedSchools() {
      db.collection("/schools").where('removed', '==', true).get().then(this.gotRemovedSchools);
    },
    getUnassignedUsers() {
      db.collection("/users").where('schoolId', '==', null).get().then(this.gotUnassignedUsers);
    },
    getSchoolUsers() {
      db.collection("/users").where('schoolId', '==', this.selectedSchool.id).get().then(this.gotAssignedUsers);
    },
    gotAssignedUsers(items) {
      console.log(items);
      this.selectedSchool.users = [];
      let _users = [];
      items.forEach((item) => {
        let data = item.data();
        data.id = item.id;
        if (data.schoolId == this.selectedSchool.id) {
          _users.push(data);
        }
      });

      this.selectedSchool.users = _users
    },
    gotUnassignedUsers(items) {
      console.log(items);
      let _unassignedUsers = [];
      items.forEach((item) => {
        let data = item.data();
        data.id = item.id;
        data.made = data.created.toDate().toLocaleString();
        _unassignedUsers.push(data);
      });
      this.unassignedUsers = _unassignedUsers
    },
    createUser() {
        let docRef = db.collection("/schools").doc(this.selectedSchool.id).collection("invites").doc();
        docRef.set({
          teacherId: ""
        })
        .then(() => {
          this.createUserWithCredentials(docRef.id);
        });
    },
    createUserWithCredentials(inviteId) {
      auth.createUserWithEmailAndPassword(this.newUserEmail, this.newUserPassword)
        .then((userCredential) => {
          //userCredential.user.sendEmailVerification();        
          this.addedUserId = userCredential.user.uid;

          return firebase.auth().signInWithEmailAndPassword(this.newUserEmail, this.newUserPassword);
        })
        .then((userCredential) => {
          console.log("Logged in new user " + userCredential);

          //Save new doc for new user
          this.newUserName = "";
          this.newUserEmail = "";
          this.newUserPassword = "";
          this.createUserError = "";

          let timestamp = firestore.FieldValue.serverTimestamp();

          const schoolTeachersRef = db.collection("/schools").doc(this.selectedSchool.id).collection("teachers").doc(this.addedUserId);
          schoolTeachersRef.update(schoolTeachersRef, {
            inviteId: inviteId,
            teacherId: this.addedUserId,
            name: this.newUserName
          })
            .then(() => {
              console.log()
              const usersRef = db.collection("/users").doc(this.addedUserId);
              usersRef.set(usersRef, {
                inviteId: inviteId,
                name: this.newUserName,
                schoolId: this.selectedSchool.id,
                created: timestamp,
                createdBy: auth.currentUser.uid
              });
            })
            .catch((error) => {
              // Handle errors
              console.error(error);
              this.createUserError = error.message;
            });
        })
        .catch((error) => {
          // Handle errors
          console.error(error);
          this.createUserError = error.message;
        });
    }
  },
  mounted() {
    this.getUnassignedUsers();
    this.getSchools();
    this.getRemovedSchools();
  },
};
</script>
