<template>
  Home
</template>

<script>
export default {
  name: 'HomeModule',
  props: {
    msg: String
  }
}
</script>
